import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

import Seo from '../../components/Seo';
import { withLayout } from '../../components/Layout';

const useAnswerStyles = makeStyles(() => ({
  image: {
    width: '100%',
    display: 'block',
    margin: 0,
  },
}));

const Page = ({ pageContext }) => {
  const { answers } = pageContext;
  const answerClasses = useAnswerStyles();

  return (
    <div>
      <Seo title="Questions and Answers from the Bible" />

      <Grid container spacing={2}>
        {answers.map(({ id, title, slug, unfurl }) => (
          <Grid item lg={4} md={6} xs={12}>
            <Button size="small" component={Link} to={`/answer/${slug}`}>
              <img
                className={answerClasses.image}
                src={unfurl}
                alt="CFC Answers"
                style={{ display: 'block', margin: 0, maxWidth: '100%' }}
              />
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'xl' });
